import React from 'react';
import '../../styles/nav.css';

const Nav = () => {
    return (
        <div className='nav'>
                
        </div>
    )
}

export default Nav
