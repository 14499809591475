import React from 'react';
import Layout from '../components/reusables/Layout';

const Home = () => {
    return (
        <div>
            <Layout>
                <div className="container">
                    <div className="sectionHeight--short">

                    </div>
                </div>
            </Layout>
        </div>
    )
}

export default Home;
