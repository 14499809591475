import React from 'react';
import Sidebar from './Sidebar';
import Nav from './Nav';

const Layout = ({children}) => {
    return (
        <div>
            <Sidebar/>
            <Nav/>

            <div className="contentHolder mt-3">
                {children}
            </div>
        </div>
    )
}

export default Layout;
