import React from 'react';
import '../../styles/nav.css';

const Sidebar = () => {

    const navlinks = [
        {
            icon: 'fas fa-dashboard',
            text: 'Dashboard'
        }, {
            icon: 'fas fa-dashboard',
            text: 'Dashboard'
        }, {
            icon: 'fas fa-dashboard',
            text: 'Dashboard'
        }, {
            icon: 'fas fa-dashboard',
            text: 'Dashboard'
        }, {
            icon: 'fas fa-dashboard',
            text: 'Dashboard'
        }
    ]

    const bottomLinks = [
        {
            icon: 'fas fa-dashboard',
            text: 'Help'
        }, {
            icon: 'fas fa-dashboard',
            text: 'Settings'
        }, {
            icon: 'fas fa-dashboard',
            text: 'Log Out'
        }
    ]

    return (
        <div className='sidebar'>
            <div className="logo">
                <img src="/images/logo.png" alt="logo"/>
            </div>

            {navlinks.map(link => (
                <li className='mt-2  mAuto'>
                    <i className={link.icon}></i>
                    <p className="sectionText--mid">{link.text}</p>
                </li>
            ))
}

            <div className="mt-5">
                {bottomLinks.map(link => (
                    <li className='mt-3 mAuto'>
                        <i className={link.icon}></i>
                        <p className="sectionText--mid">{link.text}</p>
                    </li>
                ))
}
            </div>
        </div>
    )
}

export default Sidebar
